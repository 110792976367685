import React, { useState, Suspense, useLayoutEffect } from 'react'
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';

// Homepages
const Home = React.lazy(() => import("./components/pages/Home"));
// About
const About = React.lazy(() => import("./components/pages/About"));
// Contact
const Contact = React.lazy(() => import("./components/pages/Contact"));
// Menu
const Menu = React.lazy(() => import("./components/pages/Menu"));
const Menu_cn = React.lazy(() => import("./components/pages/Menu_cn"));
const Blog = React.lazy(() => import("./components/pages/Blog"))
//const QRRegister = React.lazy(() => import("./components/pages/QRRegister"));

// Scroll to Top
const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return children || null
})

function App() {
  
  return (
    <Router basename={"/"}>
      
      <Suspense fallback={<div></div>}>
        <ScrollToTop>
        <div>
      
    </div>
          <Switch>
            {/* Home */}
            <Route exact path="/" component={Home} />
            {/* About */}
            <Route exact path="/about" component={About} />
            {/* Contact */}
            <Route exact path="/contact" component={Contact} />
            {/* Menu */}
            <Route exact path="/menu" component={Menu} />
            <Route exact path="/menu_cn" component={Menu_cn} />
            <Route exact path="/points" component={Blog} />
          </Switch>
        </ScrollToTop>
      </Suspense>
    </Router>
  );
}

export default App;
